.custom-toast {
  font-family: $font-family-sans-serif;
  font-size: 0.93rem;
  .Toastify__toast {
    border-radius: 0px;
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  &.loader-full {
    height: 70vh;
  }
}

.flex-direction-column {
  flex-direction: column !important;
}

.img-center {
  height: 100%;
}

.animation {
  width: 300px;
}

@media screen and (max-width: 990px) {
  .lottie-style {
    width: 200px;
  }
}

.ht-100 {
  height: 100vh;
}
.ht-90 {
  height: 90vh;
}
.ht-80 {
  height: 80vh;
}
.ht-70 {
  height: 70vh;
}
.ht-60 {
  height: 60vh;
}
.ht-50 {
  height: 50vh;
}
.ht-40 {
  height: 40vh;
}
.ht-30 {
  height: 30vh;
}

.border-bottom {
  border-bottom: 1px solid $secondary;
  padding-bottom: 5px;
}
.border-top {
  border-top: 1px solid $secondary;
  padding-top: 5px;
}

.fake-input {
  border: none;
  margin: 0px;
  padding: 0px;
  background: none;
}

.error-message {
  color: #df0000;
  padding: 10px;
  border: 1px solid #df0000;
  margin-bottom: 20px;
  text-align: center;
  border-radius: 8px;
  background-color: #fffdec;
}

.underline {
  text-decoration: underline !important;
}

.op-0 {
  opacity: 0 !important;
}
.op-1 {
  opacity: 1 !important;
  visibility: visible !important;
}
