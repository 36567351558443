.upload-image-preview {
  overflow: auto;
  max-height: 300px;
}
.upload-form {
  .progress {
    --bs-progress-height: 0.5rem;
  }

  .upload-details {
    font-size: 0.6rem;
    font-weight: 600;
  }

  .idle {
    color: $gray-dark-400;

    .progress {
      --bs-progress-bar-bg: #{$gray-dark-400};
    }
  }

  .uploading {
    color: $primary;

    .progress {
      --bs-progress-bar-bg: #{$primary};
    }
  }

  .stop {
    // color: #b80000;
    color: $gray-dark-400;

    .progress {
      --bs-progress-bar-bg: #b80000;
    }
  }

  position: fixed;
  // left: calc(100vw - 440px);
  // top: calc(100vh - 61px);
  bottom: 0;
  right: 0;
  z-index: 2000;
  width: 440px;
  max-width: calc(100vw - 20px);

  .card {
    --bs-card-cap-padding-y: 0.5rem;
    margin-bottom: 0px;
    border-color: $primary;

    .card-header {
      background-color: $primary;
      color: $white;
      cursor: pointer;
    }
  }

  -moz-transition: top 1s;
  -ms-transition: top 1s;
  -o-transition: top 1s;
  -webkit-transition: top 1s;
  transition: top 1s;
}

.upload-box {
  width: 400px;
  position: fixed;
  bottom: 0;
  right: 10px;
  z-index: 1051;

  background-color: rgb(255, 255, 255);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 1px $secondary solid;
  font-size: 0.75rem;
  .upload-box-body {
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: min-content;
    max-height: 250px;
    overflow-y: scroll;
  }
  .upload-box-header {
    cursor: pointer;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-color: $secondary;
    padding: 10px;
    color: $white;
    display: flex;
  }
  .progress {
    --bs-progress-height: 0.3rem;
    --bs-progress-bar-bg: #fdbf36;
  }
}

.upload-box-min {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9000;
  height: min-content;
  display: flex;
  align-items: center;
  flex-direction: column;
  .progress {
    border: 1px solid $gray-200;
    --bs-progress-height: 0.4rem;
    --bs-progress-bar-bg: #fdbf36;
    --bs-progress-bg: #ffff;
    --bs-progress-border-radius: 0;
  }
  .toggle-button {
    cursor: pointer;
    background-color: $secondary;
    border: 1px solid $gray-200;
    padding: 0 15px;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    i {
      display: block;
      height: 30px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .upload-box {
    width: 100%;
    right: 0;
  }
}

// .grow {

// height: 0;
// overflow: hidden;
// }

.event-card-icon {
  height: 15px;
  width: 15px;
}

.event-card-delete {
  pointer-events: none;
  background-color: $light;
}

.event-gallary-body,
.event-tab-content,
.event-gallary-card {
  width: auto;
  .rt-btn-name {
    margin-left: 8px;
  }
}

@media (max-width: 992px) {
  .event-gallary-body,
  .event-tab-content {
    width: auto;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .event-gallary-card {
    width: 100%;
    .rt-btn-name {
      display: none;
      margin-left: 0px;
    }
  }
}
.gallery-nav-bar {
  position: relative;
  top: initial;
  z-index: 100;
  width: calc(100% - 10px);
  background-color: #fff;
  // padding-right: 40px;
  // height: 50px;
  flex-wrap: nowrap;

  &.sticky {
    position: fixed;
    top: 70px;
    // min-width: 100%;
    width: calc(100vw - 300px);
    padding-right: 44px;
    overflow-x: scroll;
    background: transparent;
    height: 100vh;
    pointer-events: none;
  }
  .sticky-div {
    background: #fff;
    // width: 100vw;
    padding: 5px;
    border-radius: 0px 0px 8px 0px;
    pointer-events: auto;
  }
}

body[data-layout-mode="dark"] {
  .gallery-nav-bar {
    background-color: $gray-dark-200;
    .sticky-div {
      background: $gray-dark-200;
    }
  }
}

body[data-sidebar-size="sm"] {
  .gallery-nav-bar.sticky {
    width: 100vw;
  }
}

@media screen and (max-width: 900px) {
  .gallery-nav-bar {
    width: 100%;
    // overflow-x: scroll;
    // flex-shrink: 0;
    .nav {
      flex-wrap: nowrap;
    }
    &.sticky {
      //   position: fixed;
      //   top: 70px;
      width: calc(100% - 2.6rem);
      //   padding-right: 4px;
      // width:100%;
      overflow-x: scroll;
    }
  }
}

// .collection-dropdown {
//   // min-width: 100px;
// }

.share-event {
  .card-header {
    cursor: pointer;
  }
  display: flex;
  .modal-content {
    background-color: transparent;
    border: none;
  }
  .icon {
    border-radius: 50%;
    background-color: $primary !important;
    height: 2rem;
    width: 2rem;
  }
  i.bg-primary {
    color: #fff;
    background-color: transparent !important;
    &.fa-globe-africa {
      color: rgb(66, 66, 66);
    }
  }
  h4 {
    margin: 0px;
  }
  h5 {
    font-size: 1.2rem;
  }
  .rounded {
    border-radius: 50% !important;
  }

  .share-link {
    padding: 8px;
    background-color: $gray-200;
    border-radius: 10px;
  }

  .dropdown-menu {
    width: 100%;
  }

  .search-result {
    cursor: pointer;
    padding: 4px;
    &:hover {
      background-color: rgb(207, 207, 207);
    }
  }
  .search-selection {
    text-align: center;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(110, 110, 110);
    padding: 2px 8px 3px 8px;
    border-radius: 4px;
    .fas {
      color: black;
      margin-left: 5px;
      padding: 2px;
      cursor: pointer;
    }
  }
  .flex-shrink-6 {
    flex-shrink: 6 !important;
  }
  .avatar-alt {
    text-align: center;
    line-height: 3rem;
    font-size: 1.3rem;
    text-transform: uppercase;
    background-color: antiquewhite;
    border-radius: 50%;
  }
}

button.soft-dropdown {
  color: $gray-dark-200;
  background-color: transparent;
  border: 0px;
  &:hover {
    color: $gray-dark-200;
    background-color: transparent;
    border: 0px;
  }
  &:active {
    color: $gray-dark-200;
    background-color: $gray-100;
    border: 0px;
    border-radius: 0.5rem;
  }
  &:focus {
    color: $gray-dark-200;
    background-color: $gray-100;
    border: 0px;
    border-radius: 0.5rem;
  }
}

///////////// event page layout/////////////////////////////////////

.main-content {
  &.event-content {
    margin-left: 0px;
    // min-height: 100vh;
    .event {
      display: flex;
      gap: 2;
      margin-left: $event-sidebar-width;

      &.settings {
        padding-left: 50px;
      }
    }
  }
}

.footer.event-footer {
  left: $event-sidebar-width;
  @media (max-width: 991.98px) {
    left: 0px;
  }
}
body[data-sidebar-size="sm"] {
  .footer.event-footer {
    @media (max-width: 991.98px) {
      left: 0px;
    }
  }
}

.vertical-menu.event-menu {
  width: $event-sidebar-width;
  padding: 20px;
  overflow: scroll;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .sidebar-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .event-nav {
      width: 100%;
    }
  }
  .content {
    margin-top: 40px;
    // height: 100%;
  }
  #sidebar-menu ul li a {
    padding: 0px;
    padding-top: 0.8rem;
  }
}

body[data-sidebar-size="sm"] {
  .vertical-menu.event-menu {
    display: none;
  }
  .main-content.event-content {
    margin-left: 0px !important;
    // min-height: 100vh;
    .event {
      margin-left: 0px;
    }
  }
}

@media (max-width: 400px) {
  .vertical-menu.event-menu {
    display: block;
    overflow: scroll;
  }
  .main-content {
    &.event-content {
      .event {
        margin-left: 0px !important;
      }
    }
  }
}
@media (max-width: 992px) {
  .vertical-menu.event-menu {
    display: block;
    overflow: scroll;
  }
  .main-content {
    &.event-content {
      .event {
        margin-left: 0px !important;
      }
    }
  }
}

.navbar-brand-box.event-brand-box {
  width: $event-sidebar-width;
}

.event-form {
  label {
    font-weight: 600;
    font-size: 1rem;
  }
  legend {
    font-weight: 600;
    font-size: 1rem;
  }
  input {
    border-radius: 0px;
  }

  .sub-label label {
    font-weight: 400;
    font-size: 0.9rem;
  }
}

.branding-form {
  .page-header {
    margin-bottom: 2.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid $gray-200;
  }
  label {
    font-weight: 500;
    font-size: 0.9rem;
  }
  legend {
    font-weight: 600;
    font-size: 0.8rem;
  }
  input {
    border-radius: 0px;
  }

  .sub-label label {
    font-weight: 400;
    font-size: 0.9rem;
  }
}

.cover-img-sidebar {
  max-height: 300px;
  .img-fluid {
    max-height: 290px;
  }
}

@media screen and (min-width: 320px) and (max-width: 500px) {
  input {
    &.email-body {
      padding: 10px;
      height: 7rem;
    }
  }
}
@media screen and (min-width: 550px) {
  input {
    &.email-body {
      padding: 10px;
      height: 15rem;
    }
  }
}

// .cover {
//   height: 100vh;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   &.preview {
//     height: 85vh;
//   }
//   // .img-fluid {
//   //   max-height: 60%;
//   // }
//   img {
//     width: 100%; /* or any custom size */
//     height: 100%;
//     object-fit: cover;
//   }
//   .cover-text {
//     // position: absolute;
//     // top: 2%;

//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     // height: 18vh;
//     padding-top: 4rem;
//   }
//   .cover-arrow {
//     padding: 2px;
//     font-size: 1.2rem;
//     cursor: pointer;
//   }
// }

//// nav for gallery
// @media screen and (min-width: 320px) and (max-width: 900px) {
//   .gallery-nav-bar .nav {
//     flex-wrap: nowrap;
//     overflow-x: scroll;
//     overflow-y: hidden;
//   }
// }

.breaker {
  width: 95%;
  border-bottom: 1px solid gray;
  margin-top: 1rem;
  margin-bottom: 0.7rem;
}

.sidebar-details {
  font-size: 0.85rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  i {
    font-size: 1rem;
    margin-right: 3px;
  }
}

button {
  .w-100 {
    width: 100%;
  }
}

// .modal-dialog.webcam{
//   width: 54vw;
//   max-width: 54vw;
// }
// @media screen and (max-width: 500px) {
//   .modal-dialog.webcam{
//     width: 95vw;
//     max-width: 95vw;
//   }
// }

.qr-code {
  .icon {
    cursor: pointer;
  }
}

.mr-2 {
  margin-right: 2px;
}
.mr-3 {
  margin-right: 3px;
}
.mr-4 {
  margin-right: 4px;
}
.mr-5 {
  margin-right: ($spacer * 1.5);
}

.ml-2 {
  margin-left: ($spacer * 0.5);
}
.ml-3 {
  margin-left: $spacer;
}
.ml-4 {
  margin-left: ($spacer * 1.5);
}

.action-btn {
  font-size: 20px;
  // padding: 5px 10px;
}

.pin-container {
  padding: 40px;
  input {
    font-size: 1.6rem !important;
    text-align: center !important;
    width: 60px !important;
    height: 60px !important;
    margin: 10px !important;
  }
}
@media (max-width: 767.98px) {
  .pin-container {
    padding: 10px;
    input {
      font-size: 1.3rem !important;
      text-align: center !important;
      width: 50px !important;
      height: 50px !important;
      margin: 8px !important;
    }
  }
}

.blink {
  color: rgb(0, 119, 0);
  animation: blink 1s linear infinite;
}
@keyframes blink {
  0% {
    color: rgb(0, 119, 0);
  }
  50% {
    color: rgb(4, 195, 4);
    transform: scale(1.05);
  }
  100% {
    color: rgb(0, 119, 0);
    transform: scale(1);
  }
}

.live-dot {
  color: rgb(232, 123, 0);
  animation: blink-live 1s linear infinite;
}

@keyframes blink-live {
  0% {
    color: rgb(232, 123, 0);
  }
  50% {
    color: rgba(232, 124, 0, 0.2);
    transform: scale(1);
  }
  100% {
    color: rgb(232, 123, 0);
    transform: scale(1);
  }
}
