$mobile-toolbar-height: 80px;

.gallery-light-box-img {
  max-width: #{"min(1920px, 100%)"};
  max-height: #{"min(1080px, 100%)"};
  transform: translateZ(0px);
  width: 100%;
  height: auto;
}

.gallery-light-box-menu-mobile {
  .dropdown {
    .btn.btn-secondary {
      background-color: unset;
      padding: 0;
      border: unset;
      font-size: unset;
      text-transform: uppercase;
      color: unset;
    }
    .dropdown-menu {
      transform: (-80%, 0) !important;
    }
  }
  .contexify {
    font-size: 15px;
    text-transform: capitalize;
    margin-bottom: 10%;
    border-radius: 0;
  }
  .gallery-light-box-menu-mobile-option {
    color: var(--gallery-font-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    // padding: 10px;
    i {
      margin-right: 0;
      color: var(--gallery-font-color) !important;
      font-size: 18px;
      border-color: var(--gallery-background-color-2);
      background-color: var(--gallery-background-color-2);

      &::after {
        color: var(--gallery-primary-color) !important;
      }
      &.liked {
        // background-color: var(--gallery-primary-font-color) !important;
        color: var(--gallery-primary-color) !important;
      }
    }
    &.active {
      i {
        background-color: var(--gallery-primary-color) !important;
        color: var(--gallery-primary-font-color) !important;
      }
    }
    .icon-container {
      margin-right: 0;
      color: var(--gallery-font-color) !important;
      font-size: 18px;
      border-color: var(--gallery-background-color-2);
      background-color: var(--gallery-background-color-2);
      margin: 0 3px;
      font-size: 15px;
      text-align: center;
      display: inline-flex;
      width: 40px;
      height: 40px;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      color: #74788d;
      transition: all 0.4s;
      border: 1px solid #e9e9ef00;
      border-radius: 50%;
      vertical-align: middle;
    }
  }

  overflow-x: scroll;
  z-index: 101;
  font-size: 9px;
  text-transform: uppercase;
  width: 100vw;
  padding: 10px 5%;
  position: fixed;
  height: $mobile-toolbar-height;
  top: calc(100dvh - #{$mobile-toolbar-height});
  left: 0;
  background-color: var(--gallery-background-color);
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  gap: 15px;
}

.gallery-light-box-menu {
  margin: 0 20px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 25px;
  font-size: 25px;
}

.client-gallery-container.yarl__root {
  background-color: unset;
  .yarl__slide {
    padding-top: 120px;
    padding-bottom: 24px;
  }

  .yarl__slide,
  .yarl__slide_captions_container {
    background-color: transparent;
  }
  .yarl__container {
    background: var(--gallery-background-color-transparent-1);
    backdrop-filter: blur(30px);
    // background-color: transparent;
  }

  .yarl__toolbar {
    align-items: center;
    // background-color: var(--gallery-background-color);
    width: 100%;

    i.mdi {
      color: var(--gallery-font-color);

      &:hover {
        color: var(--gallery-primary-color);
      }
    }

    button.yarl__navigation_next.yarl_button {
      i.navigation {
        font-size: 47px;
      }
    }
    button.yarl__button {
      color: var(--gallery-font-color);
      filter: none;
      box-shadow: none;
      height: 30px;
      i.top {
        margin: 10px 0;
        font-size: 27px;
      }

      &:disabled {
        opacity: 0.6;
        cursor: wait;

        i {
          cursor: wait;
        }
      }
    }
  }

  .lighbox-slider {
    position: fixed;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    right: -440px;
    top: 100px;
    padding: 30px 10px 20px 20px;
    width: 440px;
    color: var(--gallery-font-color);
    background-color: var(--gallery-background-color);
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    height: 100%;
    z-index: 100;
    transition: all ease-in 0.2s;

    &.expand {
      right: 0;
      overflow-y: scroll;
    }
  }
}
@media (max-width: 1200px) {
  .client-gallery-container.yarl__root {
    .yarl__slide {
      padding-top: 1%;
      padding-bottom: calc(1% + #{$mobile-toolbar-height});
    }
    .lighbox-slider {
      border-bottom-left-radius: unset;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      display: flex;
      flex-direction: column;
      padding: 10px 10px 23px 20px;
      height: 60%;
      width: 100%;
      min-width: 0%;
      position: fixed;
      bottom: -60%;
      right: unset;
      top: unset;
      left: 0;
      transition: all ease-in 0.2s;
      cursor: pointer;

      &.expand {
        overflow-y: scroll;
        bottom: 80px;
        left: 0;
      }
    }
  }
}
.lighbox-slider.branding-options {
  height: fit-content;

  .branding-options-container {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
  }
  .branding-option-item {
    padding: 12px;
    border: 1px solid var(--gallery-background-color-3);
    background-color: var(--gallery-background-color-2);
    border-radius: 4px;
    width: 42%;
    height: min-content;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
    cursor: pointer;
    i {
      margin-right: 5px;
      font-size: 15px;
      text-align: center;
    }

    &:hover {
      background-color: var(--gallery-background-color-2);
    }
  }
}

.lightbox-shadow {
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.3);
}

.gallery-people-tab {
  overflow-x: scroll;
  pointer-events: visiblePainted;
  z-index: 5001;
  font-size: 9px;
  text-transform: uppercase;
  width: 100vw;
  padding: 20px 5%;
  position: fixed;
  top: 50px;
  left: 0;
  // background-color: #0606061f;
  // background-color: var(--gallery-background-color);
  display: flex;
  gap: 15px;
  .people-icon {
    position: relative;
    img {
      cursor: pointer;
      border-radius: 50%;
    }
  }
}

.collection-people-tab {
  padding: 10px 5%;
  display: flex;
  gap: 15px;
  position: relative;
  overflow-x: scroll;
  .people-icon {
    position: relative;
    img {
      cursor: pointer;
      border-radius: 50%;
    }
  }
  .options {
    position: absolute;
    top: -20%;
    right: -25%;
    cursor: pointer;
    // background-color: white;
  }
}
