$settings_width: 72%; //280px;
$settings_menu_width: 18%;
$settings_container_width: 380px; //$settings_width + 45px + $settings_menu_width;
$design-editor-topbar: 45px;

.design-editor-logo {
  width: min-content;
}
.design-editor-shadow {
  box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.2);
}
.design-editor-title {
  letter-spacing: 4px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
}
.design-editor {
  margin-top: $design-editor-topbar;
  background-color: #ebebeb;
  position: relative;
  display: flex;

  .client-gallery-nav-bar {
    &.sticky {
      width: calc(100% - #{$settings_container_width});
    }
  }
  .gallery-cover {
    max-height: calc(100vh - #{$design-editor-topbar}) !important;
  }
  .gallery-cover.cover-theme-marathon {
    max-height: unset !important;
  }
}

.design-editor-topbar {
  height: $design-editor-topbar;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #f5f5f5;
  // color: #ffff;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  width: 100%;
  padding: 5px 20px;
  font-size: 15px;
  z-index: 1200;
}

.design-settings-toggle-button {
  position: fixed;
  top: 0;
  padding: 5px 10px;
  width: 100%;
  z-index: 1054;
  background-color: #eeeeee;
}

.design-settings-menu {
  width: $settings_menu_width;
  height: min-content;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 20px 0px;
  pointer-events: visible;

  .nav {
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }
  .nav-item {
    width: 100%;
  }
  .nav-link {
    padding: 5px 0;
    cursor: pointer;
    i {
      font-size: 17px;
    }
    small {
      font-size: 10px;
    }
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    &:hover {
      background-color: $gray-200;
    }
    &.active {
      background-color: $fotoowl-yellow;
      color: $primary;
    }
  }
}

.design-settings-container {
  position: fixed;
  right: 0;
  padding: 20px 5px 80px 0;
  justify-content: flex-end;
  gap: 15px;
  height: 100%;
  width: $settings_container_width;
  z-index: 1051;
  display: flex;
  pointer-events: none;
}

@include media-breakpoint-down(md) {
  .design-settings-container {
    width: 100vw;
  }
}

.design-settings {
  background-color: #ffffff;
  // position: relative;
  width: $settings_width;
  padding: 20px 15px 20px 15px;
  overflow-y: scroll;
  border-radius: 15px;
  pointer-events: visible;
}
.design-settings-bottom-toolbar {
  position: fixed;
  bottom: 0;
  width: $settings_width;
  padding: 10px 20px;
  background-color: #e1e1e1;
  border-top: 1px solid #29292925;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  z-index: 1058;
}
.design-settings-inner {
  display: flex;
  flex-direction: column;
  // margin-top: 20px;
  z-index: 1054;
}
.design-preview {
  margin-right: 0;
  width: calc(100% - #{$settings_container_width});
  // border-left: 10px solid #292929;
  border-right: 1px solid #adadad;
  // height: max-content;
  // height: 100%;
  height: auto;
  box-shadow: -2px -1px 21px -6px rgba(0, 0, 0, 0.38);
  -webkit-box-shadow: -2px -1px 21px -6px rgba(0, 0, 0, 0.38);
  -moz-box-shadow: -2px -1px 21px -6px rgba(0, 0, 0, 0.38);
}

.design-setting {
  margin-top: 0px;

  // .accordion-body {
  //   max-height: 70vh;
  //   overflow-y: scroll;
  // }
}

.active-layout {
  border: 1px #b2b2b2 solid;
  background-color: #eeeeee;
}

.cover-design {
  .cover-thumbnail {
    cursor: pointer;
    padding: 2px;
    &.active {
      background-color: #fff9c5b7;
    }
    &:hover {
      background-color: #dfdfdf;
    }
  }
}

.cover-text {
  min-height: 40vh;
}

.design-cropper-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.laptop-container {
  position: relative;
  width: 100%;
  height: 85%;
  display: flex;
  justify-content: center;
}
.laptop-mask {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  img {
    width: 100%;
  }
  display: flex;
  justify-content: center;
  pointer-events: none;
  z-index: 10;
}
.laptop-cropper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 74%;
  aspect-ratio: 16 / 10;
  display: flex;
  align-items: center;
  justify-content: center;
}
.laptop-cropper-inner {
  height: 100%;
  width: 100%;
  mask-image: url(https://f000.backblazeb2.com/b2api/v2/b2_download_file_by_id?fileId=4_z33403fe7b5dae210890e081c_f1006601abcff84b9_d20231004_m081112_c000_v0001411_t0011_u01696407072627);
  mask-size: 138%;
  mask-position: center;
}

// 2
.laptop-container-2 {
  position: relative;
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.laptop-cropper-2 {
  width: 80%;
  img {
    height: 70vh;
  }
}
.laptop-cropper-inner-2 {
  height: 100%;
}

.mobile-container {
  position: relative;
  width: 100%;
  margin-top: 20px;
  height: 85%;
  display: flex;
  justify-content: center;
}

.mobile-mask {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 75vh;
  img {
    height: 100%;
  }
  display: flex;
  justify-content: center;
  pointer-events: none;
  z-index: 10;
}
.mobile-cropper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  aspect-ratio: 8 / 16;
  height: 75vh;
  display: flex;
  justify-content: center;
}
.mobile-cropper-inner {
  // mask-image: url(https://f000.backblazeb2.com/b2api/v2/b2_download_file_by_id?fileId=4_z33403fe7b5dae210890e081c_f118a59a6cc443e38_d20231005_m120053_c000_v0001401_t0047_u01696507253374);
  mask-image: url(https://f000.backblazeb2.com/b2api/v2/b2_download_file_by_id?fileId=4_z33403fe7b5dae210890e081c_f11860bb75377b488_d20231014_m122335_c000_v0001087_t0030_u01697286215764);
  mask-size: 102%;
  // width: 100%;
  //  mask-size: 82%;
  mask-repeat: no-repeat;
  mask-position: center;
}

//2
.mobile-container-2 {
  position: relative;
  width: 100%;
  margin-top: 20px;
  height: 85%;
  display: flex;
  justify-content: center;
}

.mobile-cropper-2 {
  width: 80%;
  img {
    height: 70vh;
  }
}

.device-selector-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  z-index: 100;
  .device-selector {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    background-color: $gray-200;
    border-radius: 5px;
    .screen {
      padding: 2px 5px;
      border: 1px solid rgb(48, 48, 48);
      font-family: monospace;
      font-weight: 500;
      border-radius: 3px;
      cursor: pointer;
      &:hover {
        background-color: #c2c2c2;
      }
      &.active {
        border-color: $fotoowl-yellow-light;
        background-color: $fotoowl-yellow-light;
      }
    }
    img {
      padding: 5px 5px;
      cursor: pointer;
    }
    img.active {
      background-color: $gray-600;
      border-radius: 5px;
    }
  }
}

@include media-breakpoint-down(md) {
  .design-preview {
    margin-right: 0px;
    width: 100vw;
    height: max-content;
    overflow-x: hidden;
  }
  .design-editor {
    .client-gallery-nav-bar {
      &.sticky {
        width: calc(100%);
      }
    }
  }
  // .design-settings-container {
  //   display: none;
  // }
}

.design-preview-container {
  width: 100%;
  // height: 100vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.design-preview-mobile-frame {
  // position: absolute;
  height: 80vh;
  width: auto;
  z-index: 100;
  pointer-events: none;
}
.design-preview-mobile {
  // width: 480px;
  // height: 720px;
  padding-top: 35px;
  position: absolute;
  height: calc(80vh - 1%);
  aspect-ratio: 8/16;
  mask-image: url(https://f000.backblazeb2.com/b2api/v2/b2_download_file_by_id?fileId=4_z33403fe7b5dae210890e081c_f11860bb75377b488_d20231014_m122335_c000_v0001087_t0030_u01697286215764);
  mask-position: center;
  mask-size: cover;
  background-color: #292929;
  html {
    font-size: 12px;
  }
}
.design-preview-laptop {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
}
.design-preview-modal {
  .modal-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2002;
    background-color: #2e2e2e00;
    border: none;
  }
}
.modal-close {
  background-color: $primary;
  color: $white;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  padding: 10px 13px;
  -webkit-box-shadow: -8px 11px 26px -14px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -8px 11px 26px -14px rgba(0, 0, 0, 0.75);
  box-shadow: -8px 11px 26px -14px rgba(0, 0, 0, 0.75);
}

.design-color-picker {
  height: 25px;
  width: auto;
  border-radius: 5px;
}

.theme-card {
  cursor: pointer;
  &.active {
    // background-color: $fotoowl-yellow-light;
    border: 2px solid $fotoowl-yellow;
  }
  &:hover {
    -webkit-box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.4);
    box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.4);
    // background-color: $gray-200;
  }
}

.gallery-templates {
  .nav-item {
    text-transform: capitalize;
  }
}
