$padding-laptop: 30px;
$padding-mobile: 30px;

.cover-theme-marathon {
  height: max-content;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  .cover-img-container {
    width: 100%;
    height: auto;
    width: 100%;
    position: relative;

    .cover-img-overlay {
      // top: $padding-laptop;
      // left: $padding-laptop;
      // height: calc(100% - 2 * #{$padding-laptop});
      // width: calc(100% - 2 * #{$padding-laptop});
      z-index: 3;
      background-color: var(--gallery-cover-overlay-color);
    }
  }

  img.cover-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 2;
    padding: 0;
  }
  .cover-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    gap: 5vh;
    height: calc(20%);
    min-height: unset;
    margin-top: 20px;
  }
  .event-details {
    display: flex;
    flex-direction: column;
  }
  .view-gallery-btn {
    font-size: 13px;
    padding: 12px 24px;
    border-color: var(--background-color);
    filter: brightness(0.9);
    background-color: var(--background-color);
    &:hover {
      filter: brightness(0.5);
      color: var(--cover-font-color);
    }
  }
  .branding {
    gap: 5px;
    .brand-contact {
      a {
        color: var(--cover-font-color);
      }
    }
  }
}

@media (max-width: 991.98px) {
  .cover-theme-marathon {
    .cover-img-container {
      height: 60%;
    }
    .cover-text {
      flex-direction: column;
      justify-content: center;
      gap: 0;
    }
    .branding {
      margin-top: 10px;
      .brand-container {
        .brand-logo {
          max-height: 12vh !important;
        }
      }
    }
  }
}
