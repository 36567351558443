//
// _card.scss
//

.card {
  margin-bottom: $grid-gutter-width;
  &.main-card {
    padding: 1rem;
    border: none;
    border-radius: 13px;
    width: 100%;
    -webkit-box-shadow: -3px -3px 16px 2px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: -3px -3px 16px 2px rgba(0, 0, 0, 0.08);
    box-shadow: -3px -3px 16px 2px rgba(0, 0, 0, 0.08);
  }
  &.card-clickable {
    &:hover {
      border: $primary;
      -webkit-box-shadow: -3px -3px 16px 2px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: -3px -3px 16px 2px rgba(0, 0, 0, 0.2);
      box-shadow: -3px -3px 16px 2px rgba(0, 0, 0, 0.2);
    }
  }
}

.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 15.4px;
  margin: 0 0 7px 0;
  color: #495057;
  font-weight: 600;
}

.card-title-big {
  font-size: 1.5rem;
  font-weight: 600;
}

.card-title-desc,
.card-subtitle {
  color: $card-title-desc;
  margin-bottom: 0;
  font-size: 13px;
}

.card-header-tabs {
  margin-top: -$card-cap-padding-y;
}

.card-header-pills {
  margin: -$card-cap-padding-y / 2;
}

// body[data-layout-mode="dark"] {
//   .card,
//   .card-header,
//   .modal-content,
//   .offcanvas {
//     background-color: $gray-900;
//     border-color: lighten($gray-dark-200, 4%);
//   }

//   .card-title-desc {
//     color: $gray-dark-400;
//   }
// }
