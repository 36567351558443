//
// _modal.scss
//

// body[data-layout-mode="dark"] {
//   // modal
//   .modal-header,
//   .modal-footer {
//     border-color: $gray-dark-300;
//   }
// }

.custom-modal {
  .modal-content {
    border-radius: 9px;
  }
  .card {
    position: relative;
    border: none;
    border-radius: 9px;
    margin-bottom: 0;
    max-height: 80vh;
  }
  .card-header {
    font-size: 1.2rem;
    padding: 1.35rem;
    background-color: $secondary;
    color: $white;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.115em;
    border-radius: 9px;
    border: none;
  }
  .card-body {
    overflow-y: scroll;
    padding: 30px 40px;
    margin-bottom: 40px;
  }
  .custom-modal-footer {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 10px;
    background-color: var(--gallery-background-color);
    width: 100%;
  }
  label {
    font-weight: 600;
  }
  input {
    border-radius: 0px;
  }
}

.coupon-popper {
  position: fixed;
  z-index: 1051;
  bottom: 10%;
  height: 80vh;
  pointer-events: none;
  &.left {
    left: 15%;
    transform: translateX(-50%);
  }
  &.right {
    right: 15%;
    transform: translateX(50%);
  }
}
