.cover-theme-ceremony {
  height: 100vh;
  position: relative;
  .cover-img-container {
    height: 100%;
    width: 100%;
    position: relative;
  }

  img.cover-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 2;
    padding: 0;
  }
  .cover-text {
    position: absolute;
    top: 0%;
    z-index: 4;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
    gap: 5vh;
  }
  .event-details {
    position: absolute;
    bottom: 5%;
    width: 100%;
    height: 40%;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    flex-direction: column;
    // z-index: 4;
    text-align: center;
    .event-name {
      border-bottom: 1px solid var(--event-name-color);
      padding-bottom: 5px;
      margin-bottom: 3px;
      text-transform: uppercase;
    }
    .event-date {
      font-size: 0.6rem;
    }
    .view-gallery-btn {
      margin-bottom: 5px;
    }
  }
  .social-nav-container {
    margin-bottom: 5px;
    img {
      margin: 0 5px;
    }
  }

  .branding {
    position: absolute;
    z-index: 4;
    top: 2%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

@media (max-width: 575.98px) {
  .cover-theme-ceremony {
    .event-details {
      bottom: 8%;
    }
  }
}
