$frame-margin: 20px;
.gallery-mode-registration {
  .cover-theme-frame {
    height: 80vh;
  }
}

.cover-theme-frame {
  height: 100vh;
  position: relative;
  .cover-img-container {
    height: 100%;
    width: 100%;
    position: relative;
  }
  .cover-frame {
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100% - 2 * #{$frame-margin});
    width: calc(100% - 2 * #{$frame-margin});
    z-index: 5;
    margin: $frame-margin;
    border: 1px solid var(--gallery-background-color);
    pointer-events: none;
  }

  img.cover-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 2;
    padding: 0;
  }
  .cover-text {
    position: absolute;
    top: 0%;
    z-index: 4;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
    gap: 5vh;
  }
  .event-details {
    position: absolute;
    bottom: 8%;
    width: 100%;
    height: 35%;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    flex-direction: column;
    // z-index: 4;
    padding: 0 calc(10px + #{$frame-margin});
    text-align: center;
    .event-name {
      padding-bottom: 5px;
      text-transform: uppercase;
    }
    .event-date {
      font-size: 0.8rem;
    }
    .view-gallery-btn {
      border: unset;
      border-bottom: 1px solid var(--cover-font-color);
      padding: 0 2px 5px 2px;
      margin-bottom: 5px;
      border-bottom: 1px solid transparent;
      &:hover {
        background-color: unset;
        border-bottom: 1px solid var(--gallery-cover-font-color);
      }
    }
  }
  .social-nav-container {
    margin-bottom: 5px;
    img {
      margin: 0 5px;
    }
  }

  .branding {
    position: absolute;
    z-index: 4;
    top: calc(2% + #{$frame-margin});
    width: 100%;
  }
}
