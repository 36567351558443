$add-button-color: rgb(223, 120, 2);
$border-color-hover: rgba(223, 120, 2, 0.3);
$border-color-active: rgba(223, 120, 2, 0.7);

.component-wrapper {
  .add-component {
    margin-bottom: -9px;
    height: 28px;
    z-index: 500;
    transform: translate(-50%, 0%);
    position: absolute;
    bottom: 0%;
    left: 50%;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
    i {
      align-items: center;
      border: 1px solid #e9e9ef;
      border-radius: 50%;
      display: inline-flex;
      height: 40px;
      justify-content: center;
      transition: all 0.4s;
      vertical-align: middle;
      width: 40px;
      color: $add-button-color;
      font-size: 2rem;
      background-color: rgba(255, 255, 255, 0.844);
      cursor: pointer;
      :hover {
        color: rgb(179, 97, 4);
      }
    }
    .btn {
      font-size: 1.2rem;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      // padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &:hover {
    -webkit-box-shadow: 0px 0px 0px 1px $border-color-hover;
    -moz-box-shadow: 0px 0px 0px 1px $border-color-hover;
    box-shadow: 0px 0px 0px 1px $border-color-hover;
  }
  &.active {
    -webkit-box-shadow: 0px 0px 0px 1px $border-color-active;
    -moz-box-shadow: 0px 0px 0px 1px $border-color-active;
    box-shadow: 0px 0px 0px 1px $border-color-active;
  }
}
