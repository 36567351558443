.gallery-swal.swal-modal {
  background-color: var(--gallery-background-color);
  color: var(--gallery-font-color);
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: var(--gallery-font-color);
  }
  .swal-button--confirm {
    background-color: var(--gallery-primary-color);
    color: var(--gallery-primary-font-color);
    &:hover {
      background-color: var(--gallery-primary-color-2);
    }
  }
}
