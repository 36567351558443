//
// _progress.scss
//

// Progress height small
.progress-sm {
  height: 5px;
}

// Progress height medium
.progress-md {
  height: 8px;
}

// Progress height large
.progress-lg {
  height: 12px;
}

// Progress height Extra large
.progress-xl {
  height: 16px;
}

.custom-progess {
  position: relative;
  .progress-icon {
    position: absolute;
    top: -12px;
    .avatar-title {
      background: $card-bg;
    }
  }
}

// animated-progess

.animated-progess {
  position: relative;
  .progress-bar {
    position: relative;
    border-radius: 6px;
    animation: animate-positive 2s;
  }
}

@keyframes animate-positive {
  0% {
    width: 0;
  }
}

.progress-bar {
  background-color: $primary;
}

body[data-layout-mode="dark"] {
  // progress
  .progress {
    background-color: $gray-dark-400;
  }
}

.float-container {
  // position: relative;
  .float {
    position: fixed;
    z-index: 10000;
    &.bottom-right {
      bottom: 0%;
      right: 0%;
      // transform: translate(-50%, -50%);
    }
  }
}

.download {
  font-size: 0.8rem;
  .card {
    margin-bottom: 0px;
    border: none;
    width: 340px;
    border-radius: 6px 6px 0px 0px;

    max-width: calc(100vw - 20px);
    .card-header {
      padding: 10px;
      background-color: $primary;
      color: $white;
      border-radius: 6px 6px 0px 0px;
      cursor: pointer;
      .arrow {
        @extend .mdi-chevron-down;
        @extend .mdi;
        font-size: 1.2rem;
      }
    }
  }
  .card.hidden {
    .card-body {
      height: 0px;
      margin: 0px;
      padding: 0px;
      display: none !important;
      -moz-transition: top 1s;
      -ms-transition: top 1s;
      -o-transition: top 1s;
      -webkit-transition: top 1s;
      transition: top 1s;
    }
    .card-header {
      .arrow {
        @extend .mdi-chevron-up;
        @extend .mdi;
        font-size: 1.2rem;
      }
    }
  }
}

.download-message {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 99999;
  width: 400px;
  margin-bottom: 0px;
  background-color: #ffff;
  -webkit-box-shadow: -5px -7px 23px -8px rgba(36, 36, 36, 0.75);
  -moz-box-shadow: -5px -7px 23px -8px rgba(36, 36, 36, 0.75);
  box-shadow: -5px -7px 23px -8px rgba(36, 36, 36, 0.75);
  .download-message-container {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
  }
  // padding: 20px;
  .download-message-img {
    object-fit: cover;
  }
  img.download-message-img {
    width: 130px;
    height: 200px;
    border-radius: 0.25rem;
  }
  .download-message-text {
    color: $gray-600;
    padding: 20px 15px 15px 15px;
  }
  // .download-message-prompt{

  // }
}
@include media-breakpoint-down(sm) {
  .download-message {
    width: 100%;
    img.download-message-img {
      width: 110px;
      height: 180px;
      border-radius: 0.25rem;
    }
  }
}
body[data-layout-mode="dark"] {
  .download-message {
    background-color: $gray-dark-200;
  }
}
