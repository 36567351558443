.plan_period_button {
    width: 150px;
    height: 50px;
    box-shadow: 0 0 5px #000000;
    background-color: black;
    color: white;
    
  
    &.left {
      border-top-left-radius: 20px 20px;
      border-bottom-left-radius: 20px 20px;
      border-top-right-radius: 0px 0px;
      border-bottom-right-radius: 0px 0px;
    }
  
    &.right {
      border-top-right-radius: 20px 20px;
      border-bottom-right-radius: 20px 20px;
      border-top-left-radius: 0px 0px;
      border-bottom-left-radius: 0px 0px;
    }
  
    &.clicked  {
      box-shadow: inset 0 0 5px #000000;
      background-color: white;
      color: black;
    }
  }
  