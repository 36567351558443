//
// _carousel.scss
//

.image-strip {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin-left: -2px;
  margin-right: -2px;
  //   overflow-x: auto;
  scroll-behavior: smooth;
  padding-bottom: 10;
  padding-top: 10;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
}

.image-strip-content {
  width: 100%;
  //   height: 100%;
  overflow-y: scroll;
  padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
  box-sizing: content-box; /* So the width will be 100% + 17px */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.image-strip-content::-webkit-scrollbar {
  display: none;
}

.image-strip-indicators-rounded {
  button {
    width: 10px !important;
    height: 10px !important;
    border-radius: 50% !important;
  }
}

.image-carousel .image-gallery-image {
  // z-index: 10;
  // width: 100%;
  // height: 100%;
  overflow: hidden;
  // width: auto !important;
  // max-height: calc(100vh - 150px) !important;
}

.gallery-modal {
  &.modal-content {
    background-color: transparent !important;
    border: 0px;
  }
  .image-gallery-content .image-gallery-slide {
    height: auto;
  }
  .image-gallery-content.fullscreen .image-gallery-slides {
    height: 100vh;
    display: flex;
    .image-gallery-image {
      max-height: 100vh;
    }
  }
  .image-gallery-icon {
    position: fixed;

    &.image-gallery-left-nav {
      left: 2%;
    }
    &.image-gallery-right-nav {
      right: 2%;
    }
    .image-gallery-svg {
      width: 30px;
    }
  }
}
.modal {
  background: #0c0c0cde;
}

// body[data-layout-mode="dark"]{

// }
