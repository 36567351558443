.cover-theme-no-cover,
.cover-theme-flat-color-cover {
  // height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;

  .cover-text {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    min-height: unset;
    margin: 30px 0 30px 0;

    // gap: 5vh;
  }
  .event-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .branding {
    padding: 0 25px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
  }
}

@media (max-width: 575.98px) {
  .cover-theme-no-cover,
  .cover-theme-flat-color-cover {
    .cover-text {
      margin: 10px 0 10px 0;
      flex-direction: column-reverse;
      .event-details {
        padding: 0 20px;
      }
      .branding {
        margin-top: 10px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.cover-theme-flat-color-cover {
  background-color: var(--gallery-primary-color-2);
}
