// .public-event-navbar {
//   background-color: $white;
// }
$nav-color: $gray-dark-100;
.public-event {
  min-height: 100vh;
  background-color: $gray-dark-100;
  display: flex;
  flex-direction: column;
  .container-fluid {
    min-height: 71vh;
  }
  .pagination {
    --bs-pagination-active-bg: #fdbf36;
  }
  --public-event-cover-height: 420px;
  --public-event-page-margin: 280px;
  --public-event-cover-radius: 15px;
  --public-event-cover-padding: 30px;
}
@media (max-width: 768px) {
  .public-event {
    --public-event-cover-height: 220px;
    --public-event-page-margin: 180px;
    --public-event-cover-radius: 10px;
    --public-event-cover-padding: 15px;
  }
}

.page-cont {
  margin: 0px;
  padding: 20px 20px;
  width: 100%;
  position: relative;

  .social-media-cont {
    width: 50px;
    position: fixed;
    top: 370px;
    right: 5px;
    padding-left: 5px;
    background-color: rgb(73, 80, 87);
    border-radius: 5px;
    // border: 1px solid red;
  }

  .search {
    display: flex;
    align-items: center;
    gap: 0;
    justify-content: flex-start;
    height: 35px;
    width: 450px;
    max-width: 100%;

    .search-input-wrap {
      position: relative;
      height: 100%;
      // min-width: 40%;
    }

    .search-input {
      border-radius: 15px 0px 0 15px;
      height: 100%;
      border: 1px solid #ababab;
      padding-left: 20px;
      padding-right: 20px;
      &::placeholder {
        // font-style: italic;
        padding-left: 10px;
      }
      // width: 100px;
    }

    .search-clear {
      position: absolute;
      top: 50%;
      right: 5px;
      transform: translate(-50%, -50%);
    }

    .btn.search-btn {
      border-radius: 0px 15px 15px 0px;
      height: 100%;
      padding: 0 10px;
      font-size: 15px;
    }
    .portfolio {
      z-index: 1000;
    }
  }
}
.public-event-list-container {
  position: relative;
  // margin-top: var(--public-event-page-margin);
  width: 92%;
  left: 4%;
}
.public-event-list-container-inner {
  background-color: $gray-100;
  border-radius: 18px;
  padding: 30px 0px;
}
@media (max-width: 768px) {
  .public-event-list-container {
    width: 98%;
    left: 1%;
  }
}
.public-event-cover-container {
  width: 100%;
  padding: var(--public-event-cover-padding);
  position: absolute;
  top: 0;
  left: 0;
}

.public-event-cover-container-inner {
  position: relative;
  border-radius: var(--public-event-cover-radius);
  height: var(--public-event-cover-height);
}
.public-event-cover-overlay {
  background-color: rgba(0, 0, 0, 0.462);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--public-event-cover-radius);
}
.public-event-cover-img {
  border-radius: var(--public-event-cover-radius);
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.public-event-cover-text {
  // position: absolute;
  // top: 20%;
  // left: 50%;
  // transform: translateX(-50%);
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: var(--public-event-page-margin);
  width: 100%;
  text-align: center;

  h1 {
    font-family: "Mullish", sans-serif;
    font-size: 3rem;
    color: $white;
  }
}
@media (max-width: 768px) {
  .public-event-cover-text {
    h1 {
      font-size: 1.5rem;
    }
  }
}

.stroke {
  filter: drop-shadow(2px 0 0 white) drop-shadow(0 2px 0 white)
    drop-shadow(-2px 0 0 white) drop-shadow(0 -2px 0 white);
}
.public-event-cover-brand-logo {
  height: 50px;
}
@media (max-width: 768px) {
  .public-event-cover-brand-logo {
    height: 35px;
  }
}

.public-event-cover-sm {
  width: 100%;
  display: flex;
  justify-content: center;
}

.public-gallery-nav {
  justify-content: start;
  background-color: $nav-color;

  .dropdown-menu {
    width: 300px !important;
  }
  align-items: center;

  .nav-toggler {
    color: $white;
  }
  .brandname {
    text-transform: capitalize;
    align-items: center;
    display: flex;
    // flex-wrap: wrap;
    padding: 0 10px;
  }
  .brandname-title {
    white-space: break-spaces;
    font-size: 2rem;
  }
}

.public-event-card {
  border-radius: 10px;
  border: 1px solid $gray-200;
  background-color: $white;
  img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .card-title {
    color: $gray-dark-200;
    text-transform: capitalize;
    font-weight: 800;
    font-family: "Montserrat", sans-serif;
  }
  .card-text {
    margin-top: 5px;
    color: $gray-600;
    font-size: 0.75rem;
    text-align: start;
  }
  .month {
    color: $fotoowl-yellow;
    text-transform: uppercase;
    font-size: 0.7rem;
  }
  .date {
    letter-spacing: 2px;
    font-weight: 700;
    font-size: 1.2rem;
  }
  .info-icons {
    position: absolute;
    top: 10px;
    right: 12px;
    display: flex;
    gap: 5px;
  }
  .info-icon {
    background-color: $gray-100;
    padding: 4px 9px;
    border-radius: 50%;
    i {
      font-size: 1.1rem;
    }
    // height: 30px;
    // width: 30px;
  }
  &:hover {
    transform: scale(1.02);
  }
}

.public-event-toolbar {
  background-color: white;
  border-radius: 10px;
  h2 {
    font-size: 1.2rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
}
.public-event {
  footer {
    background-color: $nav-color;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: white;
    }
    h1 {
      font-size: 70px;
    }
    .nav-social-logo {
      width: 40px;
    }
    .logo-img {
      // margin-left: 50px;
      padding: 10px;
      .logo {
        height: 80px;
        object-fit: cover;
      }
    }
    .footer_logo {
      width: 90px;
      height: 25px;
      margin-left: 30px;
      margin-bottom: 20px;
    }
  }
}

@media only screen and (max-width: 1240px) {
  .public-event {
    footer {
      .nav-social-logo {
        width: 40px;
      }
    }
  }
  .page-cont {
    .header {
      h1 {
        font-size: 50px;
      }
    }
  }
}

@media only screen and (max-width: 1080px) {
  .public-event {
    footer {
      .nav-social-logo {
        width: 35px;
      }
    }
  }
  .page-cont {
    .header {
      h1 {
        font-size: 40px;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .public-gallery-nav {
    .brandname-title {
      white-space: break-spaces;
      font-size: 1.5rem;
    }
  }

  .public-event {
    footer {
      .nav-social-logo {
        width: 20px;
      }
    }
  }
  .page-cont {
    .header {
      h1 {
        font-size: 40px;
      }
    }
  }

  .public-gallery-footer {
    flex-direction: column;
  }
  .public-event footer .logo-img {
    margin-left: 0;
    .logo {
      height: 70px;
    }
  }
}

.public-event-footer-link {
  width: 100%;
  margin-top: 20px;
  a {
    color: $white;
  }
  color: $white;
}
.public-event-footer-link-header {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 10px;
}
.public-event-footer-link-title {
  overflow-wrap: anywhere;
}
