.custom-dropdown {
  width: 100%;
  text-align: start;
}

.custom-dropdownitem {
  padding: 5px;
  font-size: 1rem;
}

.layout {
  padding: 20px;
  border: 1px solid grey;
  border-radius: 3px;
  cursor: pointer;

  img {
    height: 30px;
    color: gray;
  }
  &:hover {
    border: 1px solid black;
  }
  &.selected {
    border: 1px solid green;
  }
}

.layout-img {
  height: auto;
  width: 100%;
}

.preview-tab {
  // max-height: 40vh;
  height: auto;
  width: 100%;
}
