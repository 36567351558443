.event-access-container.gallery-modal {
  color: var(--gallery-font-color);
  .modal-content {
    background-color: unset;
  }

  .badge-soft-success {
    background-color: $success;
    color: $white;
    opacity: 0.9;
  }
  // input:-webkit-autofill {
  //   -webkit-background-clip: text;
  //   -webkit-text-fill-color: #ffffff;
  // }

  --bs-modal-bg: transparent;
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   bottom: 0;
  //   min-height: 100vh;
  width: 100%;
  background-color: var(--gallery-background-color-transparent-2);
  backdrop-filter: blur(30px);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .event-details {
    gap: 10px;
  }
  .event-name-access {
    font-size: 20px;
    color: var(--gallery-font-color);
    text-align: center;
  }
  .event-access-title {
    letter-spacing: 5px;
    text-align: center;
    color: var(--gallery-font-color);
  }

  .form-control {
    background-color: #00000044;
    border: 1px solid #b5b5b591;
    color: #ffffff;
  }
  .form-select {
    background-color: #00000044;
    border: 1px solid #b5b5b591;
    margin-top: 10px;
  }

  // .custom-check-form-group {
  //   .form-control {
  //     background-color: transparent;
  //     color: #ffffff;
  //   }
  // }

  input.event-pin-input {
    background-color: transparent;
    width: calc(4 * 55px);
    height: 55px;
    padding: 0;
    font-size: 24px;
    text-align: center;
    margin-right: 12px;
    text-transform: uppercase;
    color: #343434;
    border: 2px solid var(--gallery-font-color);
    border-radius: 10px;
    letter-spacing: 15px;
    padding-left: 15px;
    &:focus {
      border: 2px solid var(--gallery-primary-color);
    }
  }
  .event-pin-label {
    font-size: 1rem;
    letter-spacing: 8px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
}
