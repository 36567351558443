.gallery-mode-registration {
  .cover-theme-basic {
    height: 80vh;
  }
}

.cover-theme-basic {
  height: 100vh;
  position: relative;
  .fotoowl-top {
    position: absolute;
    top: 0;
    left: 1%;
  }
  .cover-img-container {
    height: 100%;
    width: 100%;
    position: relative;
  }

  img.cover-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 2;
    padding: 0;
  }
  .cover-text {
    position: absolute;
    bottom: 3%;
    z-index: 4;
    display: flex;
    flex-direction: column;
    width: 100%;
    // height: 100%;
    align-items: center;
    justify-content: flex-end;
    gap: 3vh;
  }
  .event-details {
    // position: absolute;
    // top: 50%;
    width: 100%;
    // left: 50%;
    // transform: translate(-50%, 0);
    display: flex;
    flex-direction: column;
    // z-index: 4;
  }

  &.gallery-cover {
    .branding {
      // position: absolute;
      // z-index: 4;
      // bottom: 2%;
      width: 100%;
      gap: 5px;
    }
  }
}
