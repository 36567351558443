.gallery-mode-registration {
  .cover-theme-elegant {
    height: 80vh;
  }
}

.cover-theme-elegant {
  height: 100vh;
  position: relative;
  .cover-img-container {
    height: 100%;
    width: 100%;
    position: relative;
  }
  .cover-img-overlay {
    background-color: unset;
    background: var(--gallery-cover-overlay-color);
    background: linear-gradient(
      0deg,
      var(--gallery-cover-overlay-color) 15%,
      rgba(9, 9, 121, 0) 50%,
      var(--gallery-cover-overlay-color) 85%
    );
  }

  img.cover-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 2;
    padding: 0;
  }
  .cover-text {
    position: absolute;
    bottom: 0%;
    z-index: 4;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
    gap: 5vh;
    padding: 2%;
    opacity: 0;
  }
  .event-details {
    position: absolute;
    bottom: 5%;
    width: 100%;
    // left: 50%;
    // transform: translate(-50%, 0);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    // z-index: 4;
    .view-gallery-btn {
      font-size: 12px;
      padding: 10px 24px;
      margin-top: 10px;
    }
    .event-date {
      font-weight: 200;
      font-size: 0.8rem;
    }
  }

  .branding {
    position: absolute;
    padding: 0 2%;
    // z-index: 4;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    top: 5%;
    width: 100%;
  }
}

@media (max-width: 575.98px) {
  .cover-theme-elegant {
    .event-details {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      .event-name {
        text-align: start;
      }
      width: 100%;
      padding: 0 5%;
      bottom: 8%;
      .view-gallery-btn {
        font-size: 10px;
        padding: 8px 20px;
        margin-top: 10px;
      }
    }
    .branding {
      top: 3%;
      padding: 0 3%;
    }
  }
}
