$padding: 4%;
.cover-theme-book {
  $background-color: var(--background-color);
  height: 100vh;
  position: relative;
  display: flex;

  .cover-img-container {
    height: 100%;
    width: 50%;
    position: relative;
    padding: $padding;
    background-color: var(--background-color);
    filter: brightness(0.9);
  }
  .cover-img-overlay {
    top: calc(2 * #{$padding});
    width: calc(100% - 4 * #{$padding});
    height: calc(100% - 4 * #{$padding});
    margin: 0 calc(2 * #{$padding});
  }

  img.cover-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 2;
    padding: 0;
  }
  .cover-text {
    position: relative;
    width: calc(50% - #{$padding} * 2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3vh;
    margin: $padding;
    border: 1px solid var(--gallery-primary-color-2);
    padding: $padding;
  }
  .event-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    .view-gallery-btn {
      font-size: 13px;
      padding: 12px;
      border-color: var(--gallery-primary-color);
      color: var(--gallery-primary-color);
      &:hover {
        color: var(--gallery-primary-font-color);
        background-color: var(--gallery-primary-color-3);
      }
    }
  }

  .branding {
    width: 100%;
    gap: 5px;
  }
}

@media (max-width: 767.98px) {
  .cover-theme-book {
    flex-direction: column-reverse;

    .cover-img-container {
      height: 40%;
      width: 100%;
    }
    .cover-text {
      height: 60%;
      width: calc(100% - 2 * #{$padding});
      gap: 5vh;
      justify-content: center;
      .event-details {
        .view-gallery-btn {
          font-size: 13px;
          padding: 10px;
          margin-top: 20px;
        }
      }
    }
  }
}
