$scale-down: 0.95;

.thumbnail-container {
  position: relative;
  cursor: pointer;
  &.thumbnail-shrink {
    img {
      -webkit-transform: scale($scale-down); /* Saf3.1+, Chrome */
      -moz-transform: scale($scale-down); /* FF3.5+ */
      -ms-transform: scale($scale-down); /* IE9 */
      -o-transform: scale($scale-down); /* Opera 10.5+ */
      transform: scale($scale-down);
      border-radius: 0.5rem;
    }
    .thumbnail-img {
      background-color: #d7e6ff;
    }
  }
  .thumbnail-img > img {
    border-radius: $gallery-thumbnail-border-radius
      $gallery-thumbnail-border-radius $gallery-thumbnail-border-radius
      $gallery-thumbnail-border-radius;
  }
}

body[data-layout-mode="dark"] {
  .thumbnail-shrink.thumbnail-img {
    background-color: $gray-600;
  }
}
.thumbnail-menu {
  z-index: 2;
  cursor: pointer;
  color: #dfdfdf;
  margin-left: 8px;
  margin-top: 4px;

  //   top: 1%;

  i {
    font-size: 1.3rem;
  }
}

.thumbnail-options {
  position: absolute;

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  .img-button button {
    padding: 0px;
    background-color: transparent;
    border: 0px;
    box-shadow: none;
  }
  .btn-secondary.dropdown-toggle {
    padding: 0px;
    background-color: inherit;
    border: 0px;
    box-shadow: none;
    &:focus {
      box-shadow: none;
    }
  }

  &:hover {
    .thumbnail-collection {
      display: flex;
    }
    background: rgb(37, 37, 37);
    background: linear-gradient(
      0deg,
      rgba(37, 37, 37, 0.6) 0%,
      rgba(61, 61, 61, 0.3) 30%,
      rgba(101, 101, 101, 0.2) 100%
    );
    border-radius: 5px;
  }
  .thumbnail-shrink &:hover {
    background: transparent;
    .checkbox {
      color: #1a73e8;
    }
    .thumbnail-collection {
      display: none;
    }
  }

  .thumbnail-collection {
    display: none;
    justify-content: flex-start;
    align-items: end;
    z-index: 2;
    padding-right: 2px;
    margin-bottom: 6px;
    margin-right: 4px;
    cursor: pointer;
    color: #dfdfdf;
    //   display: none;
    i {
      font-size: 1.2rem;
    }
    .collection-form {
      padding: 0px;
      i {
        font-size: 1.2rem;
      }
      button {
        padding: 0px;
      }
    }
    &.hidden {
      display: none;
    }
    .btn-add {
      border-radius: 0px;
      box-shadow: none;
    }

    input {
      &.collection-input {
        border-radius: 0px;
      }
    }
    .btn-secondary.dropdown-toggle {
      padding: 0px;
      background-color: transparent;
      border: 0px;
      box-shadow: none;
    }
    .thumbnail-name {
      font-size: 10px;
      margin: 0px 0px 5px 10px;
      flex-grow: 1;
    }
  }

  .checkbox {
    display: none;
    font-size: 1rem;
    &.checked {
      color: #1a73e8;
      display: block;
    }
    &.fas:hover {
      color: #ffffff;
    }
  }

  &:hover {
    .checkbox {
      color: $thumbnail-hover;
      border-radius: $thumbnail-border-radius;
      display: block;
    }
    .checkbox &.checked {
      color: $thumbnail-checked;
      border-radius: $thumbnail-border-radius;
    }
  }
}

// .checkbox-container {

// }
