@import url(https://unpkg.com/@videojs/themes@1/dist/city/index.css);
.vjs-custom {
  width: 100%;
  height: 100%;
}
.vjs-theme-fantasy {
  --vjs-theme-fantasy--primary: var(--gallery-primary-color);
}
.vjs-theme-city {
  --vjs-theme-city--primary: var(--gallery-primary-color);
  --vjs-theme-city--secondary: var(--gallery-primary-color);
}
