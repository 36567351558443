.gallery-modal {
  .custom-check-form-group {
    .form-control {
      background-color: var(--gallery-backgronund-color-2) !important;
      color: var(--gallery-font-color);
    }
    a {
      color: var(--gallery-primary-color);
    }
  }
}

.face-search-form {
  .form-control {
    font-size: 16px;
    background-color: var(--gallery-background-color-3);
    padding: 10px 20px;
    border-radius: 15px;
    border-color: var(--gallery-background-color-2)
      var(--gallery-background-color-2) var(--gallery-background-color-2)
      var(--gallery-background-color-2);
    border-width: 1px;
    margin-top: 5px;
  }
  .face-search-form .form-select {
    font-size: 16px;
    background-color: var(--gallery-background-color-3);
    padding: 10px 20px;
    border-radius: 15px;
    border-color: var(--gallery-background-color-2)
      var(--gallery-background-color-2) var(--gallery-background-color-2)
      var(--gallery-background-color-2);
    border-width: 1px;
  }
}
$camera-bg-color: #181818;
.registration-tnc-check-box {
  .form-check-input {
    font-size: 20px;
  }
  .form-check-input {
    height: 20px;
    width: 20px;
  }
}
.consent-label {
  width: 85%;
  font-size: 13px;
  max-width: 100%;
  margin-left: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  a {
    text-decoration: underline !important;
    // color: $primary;
  }
}
.custom-check-form-group {
  .form-control {
    display: flex;
    background-color: transparent !important;
    color: var(--gallery-font-color);
  }
  a {
    color: var(--gallery-primary-color);
  }
  .form-check-input {
    height: 20px;
    width: 20px;
    margin-left: -25px;
  }
}

.pre-registration {
  .form-control {
    font-size: 16px;
  }
  background-color: var(--gallery-background-color-2);
  border: 2px solid var(--gallery-background-color-3);
  // border: 2px solid #1b015c;
  // -webkit-box-shadow: -3px -3px 16px 2px rgba(0, 0, 0, 0.4);
  // -moz-box-shadow: -3px -3px 16px 2px rgba(0, 0, 0, 0.4);
  // box-shadow: -3px -3px 16px 2px rgba(178, 163, 163, 0.4);

  .modal-content {
    width: fit-content;
    position: relative;
    background-color: #212529;
    border-color: #3b403d;
    color: $white;

    h1,
    h2,
    h3,
    h4,
    h5,
    strong {
      color: $white;
    }

    input {
      background-color: #3b403d;
    }
  }
  .header-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      // opacity: 95%;
      border-radius: var(--bs-card-inner-border-radius)
        var(--bs-card-inner-border-radius) 0 0;
    }
  }

  .card-header {
    background-color: var(--gallery-primary-color-2);
    color: var(--gallery-primary-font-color);
    h3,
    h4,
    h5 {
      color: var(--gallery-primary-font-color) !important;
    }
  }
  .header {
    position: inherit;
    z-index: 12;

    // color: #ffff;
    h3,
    h4,
    h5 {
      color: var(--gallery-primary-font-color) !important;
    }
    // height: 200px;
  }
}

.capture-selfie {
  width: 100%;
  height: 100%;
  padding: 10px;

  &.modal-dialog {
    max-width: 100%;
    justify-content: center;
    --bs-modal-margin: 0;
  }

  .modal-content {
    width: fit-content;
    position: relative;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;

    .webcam-mask {
      // fill: var(--gallery-background-color-2);
      fill: $camera-bg-color;
    }
    svg {
      pointer-events: none;
    }
  }

  .webcam {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 50;
  }
}

// .face-only-access.event-access-container.gallery-modal {
//   .modal-body {
//     justify-content: center;
//   }
// }

.camera-selfie {
  // background-color: var(--gallery-background-color-2);
  background-color: $camera-bg-color;
  color: rgb(255, 255, 255);
  .captured-frame {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    width: 100%;
    height: 100%;
  }
  h4 {
    color: rgb(255, 255, 255) !important;
  }
}

.camera-access-error {
  position: fixed;
  z-index: 99999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--gallery-background-color-3);
  padding: 50px;
  text-align: center;
  width: 300px;
  // height: 200px;
  border-radius: 10px;
}
