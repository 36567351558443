.cloud-card{
    border-radius: 0.8rem;
}

.cloud-logo{
        height: 3rem;
        width: auto;
        background-color: $gray-100;
        padding: 5px;
        border-radius: 0.5rem;
    }
.cloud-title{
    font-size: 15.4px;
    margin: 0 0 7px 0;
    font-weight: 600;
}


