.search-bar {
  position: relative;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  left: 0;
  height: 50rem;
  overflow: hidden;
  overflow-y: hidden;
  img {
    height: 3.5rem;
  }
  .logo-txt {
    font-size: 2.2rem;
  }
}

.search-bar.transition {
  height: 12rem;
  img {
    height: 2rem;
  }
  .logo-txt {
    font-size: 1.8rem;
  }
}
