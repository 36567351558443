.text-container {
  padding: 0% 2% 0% 2%;
  &.wide {
    margin: 0px $margin-wide 0px $margin-wide;
  }
  &.regular {
    margin: 0px $margin-regular 0px $margin-regular;
  }
  &.narrow {
    margin: 0px $margin-narrow 0px $margin-narrow;
  }
}
.container {
  // padding: 0% 2% 0% 2%;
  &.wide {
    padding: 0px $margin-wide 0px $margin-wide;
  }
  &.regular {
    padding: 0px $margin-regular 0px $margin-regular;
  }
  &.narrow {
    padding: 0px $margin-narrow 0px $margin-narrow;
  }
}
