$margin-wide: 1%;
$margin-regular: 8%;
$margin-narrow: 25%;
$min-text-box-width: 10vw;

.text-layout {
  // position: relative;
  min-width: $min-text-box-width;
  min-height: 50px;
  .text-editor {
    position: relative;
    .text-toolbar {
      position: absolute;
      width: max-content;
      align-items: center;
      z-index: 600;
      background-color: rgb(25, 25, 25);
      border-radius: 5px;
      top: 0%;
      left: 0px;
      margin-top: -60px;
      -webkit-box-shadow: 0px 0px 10px 0px rgba(145, 136, 145, 1);
      -moz-box-shadow: 0px 0px 10px 0px rgba(145, 136, 145, 1);
      box-shadow: 0px 0px 10px 0px rgba(145, 136, 145, 1);
      .material-icons {
        color: rgb(197, 197, 197);
      }
    }
    .text-box-wrapper {
      position: relative;
      min-width: $min-text-box-width;
      min-height: 50px;
    }
  }

  &.active {
    .text-toolbar {
      cursor: pointer;
    }
    .text-box {
      cursor: text;
    }
    .text-box-border {
      pointer-events: none;
      position: absolute;
      top: -5%;
      left: -1%;
      height: 110%;
      width: 102%;
      border: 1px solid rgb(1, 123, 1);
    }
  }

  .text-layout-options {
    position: absolute;
    top: 0%;
    left: 10%;
  }
}
