//
// _buttons.scss
//

button,
a {
  outline: none !important;
}

@mixin button-shadow($color) {
  box-shadow: 0 2px 6px 0 rgba($color, 0.5);
}

// Button Shadows
@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-shadow($value);
  }
}

.btn-warning {
  color: $white !important;
}

.btn-outline-light {
  color: $dark;
}

//
// Soft Buttons
//

@mixin button-variant-soft($bg) {
  color: $bg;
  background-color: rgba($bg, 0.1);
  border-color: transparent;

  &:hover,
  &:focus,
  &:active {
    color: $white;
    background-color: $bg;
  }

  &:focus {
    box-shadow: 0 0 0 $btn-focus-width rgba($bg, 0.5);
  }
}

@each $color, $value in $theme-colors {
  .btn-soft-#{$color} {
    @include button-variant-soft($value);
  }
}

.btn-soft-light {
  color: $dark;
  background-color: rgba($light, 0.25);
  &:hover,
  &:focus,
  &:active {
    color: $dark;
  }
}

//
// Split Dropdown buttons
//

@mixin button-variant-split($bg) {
  background-color: lighten($bg, 5%);
  border: none;
}

@each $color, $value in $theme-colors {
  .btn-#{$color}.dropdown-toggle-split {
    @include button-variant-split($value);
  }
}

// Rounded Buttons
.btn-rounded {
  border-radius: 20% !important;
}

.btn-rounded-2 {
  border-radius: 15px !important;
}
.btn-circle {
  border-radius: 50% !important;
}
.btn.btn-shadow {
  -webkit-box-shadow: -1px -5px 33px -11px rgba(0, 0, 0, 0.83);
  -moz-box-shadow: -1px -5px 33px -11px rgba(0, 0, 0, 0.83);
  box-shadow: -1px -5px 33px -11px rgba(0, 0, 0, 0.83);
}
.btn-fotoowl {
  background-color: $fotoowl-yellow;
  &:hover {
    background-color: $fotoowl-yellow-light;
  }
}

// example 1

.btn-group-example {
  .btn {
    position: relative;

    &:first-child {
      &::before {
        display: none;
      }
    }

    &:before {
      content: "OR";
      position: absolute;
      font-size: 10px;
      width: 22px;
      height: 22px;
      line-height: 22px;
      border-radius: 50%;
      background-color: $gray-600;
      color: $light;
      left: -12px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }
  }
}

// Example 2

.btn-label {
  position: relative;
  padding-left: 44px;
  border: none;

  .label-icon {
    position: absolute;
    width: 32px;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba($white, 0.15);
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.btn-light {
    .label-icon {
      background-color: rgba($dark, 0.1);
    }
  }

  &.btn-soft-light {
    .label-icon {
      background-color: rgba($dark, 0.05);
    }
  }
}

body[data-layout-mode="dark"] {
  // btn
  .btn {
    color: $gray-dark-700;
  }

  // buttons
  .btn-light {
    color: $gray-dark-500;
    background-color: $gray-dark-300;
    border-color: $gray-dark-300 !important;
    box-shadow: 0 2px 6px 0 rgba($gray-dark-300, 0.5);
  }

  .btn-outline-light {
    color: $gray-dark-500;
    border-color: $gray-dark-300;
    &:hover {
      color: $gray-dark-500;
      background-color: $gray-dark-300;
      border-color: $gray-dark-300;
    }
  }

  .btn-check:focus + .btn-light,
  .btn-light:focus,
  .btn-check:focus + .btn-outline-light,
  .btn-outline-light:focus {
    box-shadow: 0 0 0 $btn-focus-width rgba($gray-dark-300, 0.5);
  }
}

.btn {
  // border-radius: 0px;

  box-shadow: none;
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 -10px #5e5e5e;
  }
  40% {
    box-shadow: 0 0 20px #4c4c4c;
  }
  60% {
    box-shadow: 0 0 20px #282828;
  }
  100% {
    box-shadow: 0 0 -10px #151515;
  }
}

.btn-glow {
  animation: glowing 5000ms infinite;
}

@mixin button-bg($color) {
  background: $color;
  &:hover {
    -webkit-box-shadow: -3px -3px 10px 2px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: -3px -3px 10px 2px rgba(0, 0, 0, 0.2);
    box-shadow: -3px -3px 10px 2px rgba(0, 0, 0, 0.2);
  }
}

@each $color, $value in $theme-bg {
  .btn-#{$color} {
    @include button-bg($value);
  }
}
