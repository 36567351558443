.website-cover {
  position: relative;
  .cover-text {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    width: 80%;

    color: white;
    // text-transform: uppercase;
    text-align: center;
    .title {
      font-size: 4rem;
    }
    .subtitle {
      font-size: 1rem;
      letter-spacing: 4px;
    }
  }
  @media (max-width: 991.98px) {
    .cover-text {
      .title {
        font-size: 3rem;
      }
    }
  }
  @media (max-width: 767.98px) {
    .cover-text {
      .title {
        font-size: 2rem;
      }
    }
  }
}

.portfolio-gallery-cover {
  position: relative;
  .cover-text {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;

    color: white;
    // text-transform: uppercase;
    text-align: center;
    .title {
      font-size: 4rem;
    }
    .subtitle {
      font-size: 1rem;
      letter-spacing: 4px;
    }
  }
  @media (max-width: 991.98px) {
    .cover-text {
      .title {
        font-size: 3rem;
      }
    }
  }
  @media (max-width: 767.98px) {
    .cover-text {
      .title {
        font-size: 2rem;
      }
    }
  }
}
