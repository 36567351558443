.builder {
  scroll-behavior: smooth;
  .editor-container.container-fluid {
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
    position: relative;
    .row.editor-row {
      margin: 0px;
      padding: 0px;
    }

    .editor {
      position: relative;
      .component-wrapper {
        cursor: move;
      }

      margin-right: $toolbox-size;
      min-height: 100vh;
      @media (max-width: 767.98px) {
        margin-right: 0px;
      }

      .page {
        min-height: 100vh;
        padding-bottom: 25px !important;
      }
    }
  }
  .toolbox {
    position: fixed;
    top: 0%;
    right: 0%;
    width: $toolbox-size;
    z-index: 800;
    min-height: 100vh;
    background-color: white;
    height: 100%;
    padding: 10px;
    -webkit-box-shadow: 0px 0px 6px 1px rgba(143, 137, 136, 0.35);
    -moz-box-shadow: 0px 0px 6px 1px rgba(143, 137, 136, 0.35);
    box-shadow: 0px 0px 6px 1px rgba(143, 137, 136, 0.35);
    .nav-item {
      cursor: pointer;
      &.active {
        background-color: rgb(221, 221, 221);
        color: white;
        border-radius: 5px;
      }
    }
  }
  .component-editor {
    overflow-y: scroll;
    position: fixed;
    top: 0%;
    width: $toolbox-size;
    z-index: 1000;
    // min-height: 100vh;
    background-color: white;
    height: 100%;
    right: -$toolbox-size;
    -webkit-transition: all 0.1s ease-in;
    -moz-transition: all 0.1s ease-in;
    -o-transition: all 0.1s ease-in;
    transition: all 0.1s ease-in;

    -webkit-box-shadow: 0px 0px 6px 1px rgba(143, 137, 136, 0.35);
    -moz-box-shadow: 0px 0px 6px 1px rgba(143, 137, 136, 0.35);
    box-shadow: 0px 0px 6px 1px rgba(143, 137, 136, 0.35);

    &.active {
      // display: block;
      right: 0%;
      min-height: 100vh;
    }
    .component-editor-inner {
      padding: 20px;
    }
  }
  // a {
  //   pointer-events: none;
  // }
  .component-options {
    position: absolute;
    top: 0;
    right: 10px;
    margin-top: -40px;
    padding: 4px;
    border-radius: 4px;
    background-color: #df7802;
    .mdi {
      color: white;
    }
  }
}

.preview-page {
  &.modal-dialog {
    height: max-content;
  }
  .modal-body {
    display: d-flex;
    flex-direction: column;
    padding: 0px;
  }
  .preview-header {
    z-index: 10000;
    position: fixed;
    top: 0%;
    left: 0%;
    height: 40px;
    width: 100%;
    background-color: rgb(0, 0, 0);

    .btn {
      color: white;
    }
    z-index: 10000;
  }
  .preview-body {
    .image-slider > .fullscreen {
      height: calc(100vh - 40px);
    }
    margin-top: 40px;
    top: 0px;
    left: 0px;
    position: absolute;
  }
}

.component-box {
  position: relative;
  .component-header {
    font-size: 1.3rem;
    font-weight: 500;
    text-transform: uppercase;
    position: absolute;
    top: 0%;
    left: 0%;
    padding: 20px;
    z-index: 200;
  }
  .component-sidebar {
    position: absolute;
    top: 0%;
    left: 0%;
    padding: calc(50px + 1.3rem) 20px 20px 20px;
    width: 200px;
    z-index: 100;
    background-color: white;
    min-height: 100vh;
  }

  .component-container {
    margin-top: 2%;
    padding: 2%;
    margin-left: 200px;
  }
  @media (max-width: 767.98px) {
    .component-header {
      font-size: 1rem;
    }
    .component-container {
      margin-left: 0px;
    }
  }
  .component-option {
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: antiquewhite;
    border: 5px solid rgba(128, 128, 128, 0.633);
    cursor: pointer;
    &:hover {
      -webkit-box-shadow: 0px 0px 14px 1px rgba(145, 136, 145, 0.52);
      -moz-box-shadow: 0px 0px 14px 1px rgba(145, 136, 145, 0.52);
      box-shadow: 0px 0px 14px 1px rgba(145, 136, 145, 0.52);
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .nav-link {
    margin-bottom: 20px;
    &.active {
      background-color: black;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      color: white;
    }
  }
}

.mdi {
  cursor: pointer;
}
.back-button {
  position: absolute;
  top: 50%;
  left: 1%;
  transform: translate(0%, -50%);
  cursor: pointer;
}

.dropzone {
  .dropzone-area {
    font-family: "Montserrat", sans-serif;
    h4 {
      font-size: 0.9rem;
      color: rgb(174, 174, 174);
    }
    font-size: 0.8rem;
    color: rgb(174, 174, 174);
    background-color: rgba(187, 187, 187, 0.2);
    text-align: center;
    // border: 1px dashed rgb(133, 133, 133);
    border-radius: 5px;
    padding: 20px 20px 20px 20px;
  }
}

// event card

.builder.event-card {
  height: 100%;
  &.selected {
    border: 1px solid rgb(13, 110, 253);
  }
  &.card {
    margin-bottom: 0px;
  }
  .event-card-header {
    padding: 5px;
  }
  .row.display-flex {
    display: flex;
    flex-wrap: wrap;
  }
  .row.display-flex > [class*="col-"] {
    display: flex;
    flex-direction: column;
  }
}

.gallery-selection {
  .img-selection {
    // padding-left: 10px;
    // padding-right: 10px;
    position: relative;
    // border: 1px solid black;
    .option {
      display: none;
      position: absolute;
      top: 2px;
      left: 5px;
      font-size: 1.2rem;
    }

    &:hover {
      .option.hover {
        display: block;
      }
      box-shadow: 0 0.125rem 0.25rem rgba($black, 0.075);
    }
    &.selected {
      .option.selected {
        display: block;
      }
      background-color: rgb(178, 205, 255);
    }
    &.selected:hover {
      .option.hover {
        display: none;
      }
    }
  }
}

.selected-image-container {
  max-height: 50vh;
  overflow-y: scroll;
  .mdi-delete:hover {
    color: rgb(203, 4, 4);
  }
}
.selected-image-tab {
  border: 1px solid rgb(104, 104, 104);
  border-radius: 4px;
  margin-top: 5px;
}

.divider {
  border-top: 1px solid rgb(178, 178, 178);
  width: 100%;
  padding-top: 20px;
}
