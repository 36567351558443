.linkedin-post-preview {
  // margin: 8px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;

  .post-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .user-avatar {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 10px;
    }

    .user-info {
      .user-name {
        margin: 0;
        font-size: 0.9rem;
        font-weight: 600;
        font-family: "Mulish", sans-serif;
        color: black;
      }
      .user-title {
        margin: 0;
        font-size: 0.75rem;
        color: gray;
      }
      .post-time {
        font-size: 0.7rem;
        color: #787878;
      }
    }
  }

  .post-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-top: 10px;
    border: 1px solid #c5c5c5;
  }

  .post-caption {
    margin-top: 10px;
    font-size: 0.8rem;
    color: #333;
  }
  .comments-count {
    display: flex;
    justify-content: end;
    width: 100%;
    color: #787878;
    font-size: 0.65rem;
    font-weight: 200;
  }

  .post-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    button {
      color: #282828; // LinkedIn blue
      font-weight: 600;
      font-size: 0.7rem;

      i {
        margin-right: 5px;
      }
    }
  }
}
.spotlight {
  .linkedin-post-btn {
    background-color: #0a66c2;
    padding: 2px 10px;
    width: 100%;
    border-color: #0a66c2;
  }
}
