.portfolio {
  padding: 0px 5% 0px 5%;
  .portfolio-event {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    .event-heading {
      text-transform: uppercase;
    }
    .event-subheading {
      text-transform: uppercase;
      font-size: 0.6rem;
    }

    img {
      width: 100%;
      //   height: auto;
      object-fit: cover;
    }
  }
}
