.hashtrack {
  height: fit-content;
  &.download-basic {
    position: fixed;
    bottom: 0px;
    right: 0;
    z-index: 99999;
    width: 450px;
    margin-bottom: 0px;
    //   background-color: #ffff;
    background-color: var(--gallery-background-color);
    color: var(--gallery-font-color);
    -webkit-box-shadow: -5px -7px 23px -8px rgba(36, 36, 36, 0.4);
    -moz-box-shadow: -5px -7px 23px -8px rgba(36, 36, 36, 0.4);
    box-shadow: -5px -7px 23px -8px rgba(36, 36, 36, 0.4);

    &.preview {
      position: unset;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      z-index: 1;
    }

    .container {
      display: flex;
      position: relative;
      width: 100%;
      height: 100%;
    }

    .preview-img {
      object-fit: cover;
      width: 130px;
      height: 200px;
      border-radius: 0.25rem;
    }

    // img.download-message-img {

    // }
    .download-message-text {
      padding: 20px 15px 15px 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .social-nav-container {
    display: flex;
    flex-wrap: wrap;
  }
}

.download-message {
  .btn-outline-light {
    color: var(--gallery-font-color);
    &:hover {
      background-color: var(--gallery-primary-color);
    }
  }
  .btn-soft-secondary {
    color: var(--gallery-font-color);
  }
}
@include media-breakpoint-down(sm) {
  .download-message {
    bottom: 10%;
    right: 0;
    width: 100%;
    img.download-message-img {
      width: 110px;
      height: 180px;
      border-radius: 0.25rem;
    }
  }
}

.icon-bg-white {
  background-color: #ffffff91;
  padding: 4px;
  border-radius: 50%;
  // height: 35px;
  // width: 35px;
}

.hashtrack-settings {
}
