.comment-gallery-row {
  margin-top: 20px;
  max-width: 500px;
  border-bottom: 2px solid grey;
  padding-bottom: 10px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  display: block;

  .comment-img {
    width: 100%;
    display: block;
    cursor: pointer;
  }

  .comment-img-name2 {
    text-align: center;
    margin-bottom: 5px;
  }

  .comment-list {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 5px;
    margin-top: 15px;
  }

  .comment {
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: 10px;
  }

  .comment-avatar {
    min-width: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .comment-info-container {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .comment-top-info {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    gap: 5px;
  }

  .comment-time {
    opacity: 0.5;
  }

  .comment-text {
    font-size: 14px;
  }

  .comment-show-all-less {
    opacity: 0.5;
    cursor: pointer;
  }

  .add-comment {
    display: flex;
    flex-direction: row;
    align-items: end;
    gap: 10px;
    width: 100%;
  }

  .add-comment-blank-space {
    min-width: 32px;
  }

  .add-comment-input {
    border: none;
    outline: none;
    border-bottom: 1px solid grey;
    flex-grow: 1;
  }
}

.client-comment {
  display: flex;
  flex-direction: column;

  .comment-img {
    width: 100%;
    display: block;
    cursor: pointer;
  }

  .comment-img-name {
    text-align: center;
    margin-bottom: 5px;
  }

  .comment-list {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 18px;
    margin-top: 15px;
    flex-grow: 1;
  }

  .comment {
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: 10px;
  }

  .comment-avatar {
    min-width: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .comment-avatar.profile-name {
    font-size: 0.9rem;
    padding: 20px;
  }

  .comment-info-container {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .comment-top-info {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    gap: 5px;
  }

  .comment-time {
    opacity: 0.5;
  }

  .comment-text {
    font-size: 14px;
  }

  .comment-show-all-less {
    opacity: 0.5;
    cursor: pointer;
  }

  .add-comment {
    display: flex;
    flex-direction: row;
    align-items: end;
    gap: 10px;
    width: 100%;

    .btn {
      font-weight: 600;
      border-radius: 20px;
    }
  }

  .add-comment-blank-space {
    min-width: 32px;
  }

  .add-comment-input {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    outline: none;

    flex-grow: 1;
    background-color: var(--gallery-background-color-transparent-2);
    padding: 10px 20px;
    border-radius: 15px;
    border-color: var(--gallery-background-color-2)
      var(--gallery-background-color-2) var(--gallery-background-color-2)
      var(--gallery-background-color-2);
    border-width: 1px;
    color: var(--gallery-font-color);
  }
}

.collection-checkbox-container {
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  .check-div {
    border: 2px;
    margin: 5px;
    padding: 5px;
    background-color: antiquewhite;
    border-radius: 4px;
  }

  Input {
    margin-right: 5px;
  }
}

.comments-heading {
  text-transform: uppercase;
  letter-spacing: 2px;
}

.comment-container {
  height: 100%;
  // overflow: scroll;
  position: relative;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // flex-wrap: wrap;
  // background-color: white;
}

.comment-img-container {
  position: relative;
  padding: 15px 0px;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

.comment-img-name {
  background-color: #13131391;
  color: white;
  position: absolute;
  bottom: 0;
  left: 0%;
  padding-left: 30px;
  width: 100%;
  font-size: 1rem;
  // transform: translate(-50%, 0);
}

.comment-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.comment-list-container {
  background-color: white;
  padding: 30px 10px 20px 20px;
  min-width: 440px;
  border-radius: 10px;
}

body[data-layout-mode="dark"] {
  .comment-list-container {
    background-color: $gray-dark-100;
  }
}

.comment-container-new {
  position: fixed;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  right: -440px;
  top: 100px;
  padding: 30px 10px 20px 20px;
  width: 440px;
  width: fit-content;
  background-color: var(--gallery-background-color);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  height: 100%;
  z-index: 100;
  transition: all ease-in 0.2s;
  &.expand {
    right: 0;
    overflow-y: scroll;
  }
}

@media (max-width: 1200px) {
  .comments-heading {
    font-size: 0.7rem;
  }

  .comment-img-container {
    width: 100%;
    height: 92%;
    background-color: black;
    padding: 0;
  }

  .comment-container {
    width: 100%;
    min-height: 50%;
    display: block;
  }

  .comment-img-name {
    bottom: 5%;
    left: 0;
    font-size: 0.8rem;
  }

  .comment-container-new {
    display: flex;
    flex-direction: column;
    padding: 10px 10px 95px 20px;
    height: 75%;
    width: 100%;
    min-width: 0%;
    position: fixed;
    top: 100%;
    right: unset;
    bottom: unset;
    left: 0;
    transition: all ease-in 0.2s;
    cursor: pointer;
    color: var(--gallery-font-color);
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: var(--gallery-font-color);
    }

    &.expand {
      overflow-y: scroll;
      top: 25%;
      bottom: 20%;
      left: 0;
    }
  }

  .comment-slider {
    position: absolute;
    top: -30px;
    right: 50%;
    // transform: translateY(5%);
    transform: translate(50%, 10px);
    animation: move 0.8s;
    animation-iteration-count: 10;

    i {
      font-size: 2rem;
    }
  }

  @keyframes move {
    0% {
      // transform: scale(1);
      transform: translate(50%, 10px);
    }

    50% {
      // transform: scale(1.2);
      transform: translate(50%, 20px);
    }

    100% {
      // transform: scale(1);
      transform: translate(50%, 10px);
    }
  }
}
