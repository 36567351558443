.navbar {
  .navbar-toggler > .mdi {
    color: white;
    font-size: 2rem;
  }
  padding: 1% 2% 1% 2%;
  width: 100%;
  .navbar-brand {
    flex-grow: 1;
    font-size: 1.7rem;
  }
  a,
  a:hover {
    color: white;
  }
  .nav-item {
    a {
      text-transform: uppercase;
    }
  }
  .navbar-collapse {
    justify-content: flex-end;
  }
  @media (max-width: 767.98px) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}
