.image-slider {
  .fullscreen {
    height: 100vh;
    &.dummy {
      z-index: 100;
      width: 100%;
    }
    .swiper {
      height: 100%;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}

.cover.cover-gallery {
  height: 100vh;
  .swiper {
    height: 100%;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.image {
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
  &.image-full {
    width: 100%;
  }
  &.image-lg {
    width: 80%;
  }
  &.image-sm {
    width: 60%;
  }
  // &.image-custom {

  // }
}
