$frame-sm-margin: 15px;
$frame-margin: 20px;

.cover {
  .brand {
    max-width: 100%;

    .brandname {
      font-family: "Montserrat", sans-serif;
      font-size: 1.6rem;
    }

    .contact {
      font-family: "Montserrat", sans-serif;
      font-size: 0.7rem;
    }

    img.brandlogo {
      max-height: 90px;
      width: auto;
      max-width: 100%;

      &.xl {
        max-height: 140px;
      }

      &.lg {
        max-height: 120px;
      }

      &.sm {
        max-height: 60px;
      }
    }
  }

  &.design-setting {
    height: 100%;

    .title {
      font-size: calc(var(--font-scale) * 2rem);
    }

    .date {
      font-size: 0.5rem;
    }

    .social-nav {
      .icon {
        height: 13px;
      }

      .social-nav-container {
        gap: 5px;
      }
    }
    .brand {
      .brandname {
        font-family: "Montserrat", sans-serif;
        font-size: 0.8rem;
      }

      .contact {
        font-family: "Montserrat", sans-serif;
        font-size: 0.45rem;
      }

      img.brandlogo {
        height: 40px;
        width: auto;

        &.xl {
          height: 80px;
        }

        &.lg {
          height: 60px;
        }

        &.sm {
          height: 30px;
        }
      }
    }
  }
}

.cover {
  .cover-overlay {
    background: var(--overlay-color);
    opacity: 1;
    height: 100%;
    width: 100%;
    z-index: 950;
  }

  .overlay-text {
    z-index: 950;
  }
}

@media (max-width: 575.98px) {
  .cover {
    .brand {
      img.brandlogo {
        &.xl {
          height: 100px;
        }

        &.lg {
          height: 80px;
        }

        &.sm {
          height: 60px;
        }
      }
    }
  }
}

/////////////////////////////////////////////////
.cover {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.dummy {
  display: hidden;
}

.cover {
  color: white;

  a {
    color: white;
  }

  img.cover-img {
    width: 100%;
    /* or any custom size */
    height: 100%;
    object-fit: cover;
    z-index: 900;
    padding: 0;
    // opacity: 0;
  }

  .social-nav {
    position: absolute;
    top: 5%;
    right: 3%;
    transform: translate(0%, 0%);
    display: flex;
    align-items: center;
  }

  .title {
    font-size: calc(var(--font-scale) * 4rem);
  }

  .date {
    font-size: 1rem;
  }

  /////////////////////////////////// theme specific
  &.center {
    .event-detail {
      position: absolute;
      bottom: 30%;
      left: 50%;
      transform: translate(-50%, 0%);
    }

    .brand {
      position: absolute;
      bottom: 5%;
      left: 50%;
      transform: translate(-50%, 0%);
    }
  }

  &.left {
    .event-detail {
      position: absolute;
      top: 50%;
      left: 3%;
      transform: translate(0%, -50%);
    }

    .brand {
      position: absolute;
      bottom: 5%;
      left: 3%;
      transform: translate(0%, 0%);
    }
  }

  &.left_bottom {
    .event-detail {
      position: absolute;
      bottom: 5%;
      left: 3%;
      transform: translate(0%, 0%);
    }

    .brand {
      position: absolute;
      top: 50%;
      left: 3%;
      transform: translate(0%, -50%);
    }
  }

  &.stripe {
    .dummy {
      display: block;
      position: absolute;
      top: $frame-margin;
      left: $frame-margin;
      height: calc(100% - 2 * #{$frame-margin});
      width: calc(100% - 2 * #{$frame-margin});
      border-top: 2px solid white;
      border-bottom: 2px solid white;
    }

    .event-detail {
      position: absolute;
      top: 50%;
      left: 3%;
      transform: translate(0%, -50%);
    }

    .brand {
      position: absolute;
      bottom: calc(#{$frame-margin} + 2%);
      left: 3%;
      transform: translate(0%, 0%);
    }

    .social-nav {
      top: calc(#{$frame-margin} + 2%);
    }
  }

  &.border {
    padding: 10px;
  }

  &.vintage {
    color: black;

    a {
      color: black;
    }

    padding: 1%;

    justify-content: start;

    .cover-img {
      height: 75%;
    }
    .cover-overlay {
      height: 75%;
    }

    .event-detail {
      position: absolute;
      bottom: 5%;
      left: 50%;
      transform: translate(-50%, 0%);
    }

    .brand {
      position: absolute;
      bottom: 5%;
      left: 3%;
      transform: translate(0%, 0%);
    }

    .social-nav {
      top: 4%;
    }
  }

  &.frame {
    .dummy {
      display: block;
      position: absolute;
      top: $frame-margin;
      left: $frame-margin;
      height: calc(100% - 2 * #{$frame-margin});
      width: calc(100% - 2 * #{$frame-margin});
      border: 2px solid white;
    }

    .event-detail {
      position: absolute;
      bottom: calc(#{$frame-margin} + 2%);
      left: 50%;
      transform: translate(-50%, 0%);
    }

    .brand {
      position: absolute;
      bottom: calc(#{$frame-margin} + 2%);
      left: calc(#{$frame-margin} + 2%);
      transform: translate(0%, 0%);
    }

    .social-nav {
      top: calc(#{$frame-margin} + 1%);
      right: calc(#{$frame-margin} + 1%);
    }
  }
}

.social-nav-container {
  display: flex;
  gap: 30px;
  align-items: center;
}

.icon {
  &.fb {
    background: url("../../../images/icons/facebook_white.png");
    height: 20px;
    width: 20px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  &.insta {
    background: url("../../../images/icons/insta_white.png");
    height: 20px;
    width: 20px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  &.web {
    background: url("../../../images/icons/web-white.png");
    height: 20px;
    width: 20px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  &.mail {
    background: url("../../../images/icons/mail-white.png");
    height: 20px;
    width: 20px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  &.youtube {
    background: url("../../../images/icons/youtube-white.png");
    height: 20px;
    width: 20px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  &.phone {
    background: url("../../../images/icons/phone-white.png");
    height: 15px;
    width: 15px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  &.face-recognition {
    background: url("../../../images/icons/facial-recognition-black.png");
    height: 15px;
    width: 15px;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

span.icon {
  float: left;
  width: 10px;
  height: 40px;

  &.download {
    background: url(../../../images/icons/download-white.png) no-repeat;
  }
}

//mobile
@media (max-width: 575.98px) {
  .social-nav-container {
    gap: 0px;
  }

  .icon {
    background-size: 12px;
  }

  .brand-small {
    font-size: 18px;
  }

  .cover {
    .brand {
      width: 100%;
    }

    .event-detail {
      width: 90vw;
      word-wrap: break-word;
    }

    img.cover-img {
      width: 100%;
      /* or any custom size */
      height: 100%;
      object-fit: cover;
    }

    .title {
      font-size: calc(var(--font-scale) * 1.8rem);
    }

    .date {
      font-size: 0.7rem;
    }

    .social-nav {
      right: 7%;
      top: 3%;
    }

    &.frame {
      .event-detail {
        bottom: 20%;
        top: unset;
        transform: translate(-50%, 0%);
        max-width: calc(100% - 50px);
      }

      .brand {
        left: 50%;
        bottom: 4%;
        transform: translate(-50%, 0%);
      }

      .social-nav {
        top: calc(#{$frame-margin} + 1%);
        right: calc(#{$frame-margin} + 2%);
      }
    }

    &.left {
      .brand {
        left: 0;
      }
    }
    &.left_bottom {
      .title {
        font-size: calc(var(--font-scale) * 1.8rem);
      }

      .event-detail {
        top: unset;
        bottom: 3%;
        transform: translate(0%, 0%);
      }

      .brand {
        top: 50%;
        left: 50%;
        // right: 3%;
        transform: translate(-50%, -50%);
      }
    }

    &.stripe {
      .event-detail {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .brand {
        left: 50%;
        transform: translate(-50%, 0%);
      }
    }

    &.vintage {
      .cover-img {
        height: 60%;
      }

      .event-detail {
        top: unset;
        width: 80vw;
        bottom: 1%;
        line-height: 1.5;
        transform: translate(-50%, 0%);
      }

      .brand {
        top: 65%;
        left: 50%;
        transform: translate(-50%, 0%);
      }
    }
  }
}

@media screen and (min-width: 450px) and (max-width: 950px) {
  .cover {
    .title {
      font-size: calc(var(--font-scale) * 2.4rem);
    }

    .date {
      font-size: 0.8rem;
    }

    &.vintage {
      padding-bottom: 20%;

      .event-detail {
        top: 90%;
      }
    }
  }
}

@media screen and (max-width: 990px) {
  .cover {
    &.design-setting {
      .title {
        font-size: calc(var(--font-scale) * 1.3rem);
      }

      .date {
        font-size: 0.4rem;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .cover {
    &.design-setting {
      .title {
        font-size: calc(var(--font-scale) * 0.9rem);
      }

      .date {
        font-size: 0.25rem;
      }
    }
  }
}

body[data-layout-mode="dark"] {
  .cover {
    &.vintage {
      color: $white;

      a {
        color: $white;
      }
    }
  }
}

.overlay-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.pre-registration {
  border: 2px solid var(--gallery-background-color-2);
  // &.main-card {
  //   border: 2px solid var(--gallery-background-color-2);
  //   -webkit-box-shadow: -3px -3px 16px 2px rgba(0, 0, 0, 0.4);
  //   -moz-box-shadow: -3px -3px 16px 2px rgba(0, 0, 0, 0.4);
  //   box-shadow: -3px -3px 16px 2px rgba(0, 0, 0, 0.4);
  // }
}

.easeload {
  opacity: 0;
  // visibility: hidden;
  transition: opacity 2s ease;
  -webkit-transition: opacity 2s ease;
  -moz-transition: opacity 2s ease;
  -ms-transition: opacity 2s ease;
  -o-transition: opacity 2s ease;
  // animation: fade-in 5s ease;
  // visibility: visible;
  // transition: visibility 2s ease, opacity 2s ease;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
